import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


import logo from '../assets/brand/logo.svg'
import avatar from '../assets/brand/avatar.svg'
import monogram from '../assets/brand/monogram.svg'

import ActionAreaCard from '../components/card'
import ActionAreaCardWide from '../components/card-wide'

import '../styles/App.css';

import ResponsiveAppBar from '../components/appbar';

import Footer from '../components/footer'
import { ScrollRestoration } from "react-router-dom";


import { useNavigate } from "react-router-dom";


/// thumbnail images
import thumb_glorifi from '../assets/thumbs/thumbnail_glorifi.png'
import thumb_eddiebauer from '../assets/thumbs/thumbnail_eddiebauer.png'
import thumb_microsoft from '../assets/thumbs/thumbnail_microsoft.png'
import thumb_silverback from '../assets/thumbs/thumbnail_silverback.png'


function App() {

  const navigate = useNavigate();


  return (
    <Container maxWidth="lg" sx={{}}>

      <ResponsiveAppBar showLogo={true} />

      <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>

        <Grid item xs={10}>

          <Typography variant="h1" color="text.primary" sx={{ marginBottom: 6 }}>
          I’m a designer specializing in creating design systems and digital user interfaces that are scalable and efficient.
          </Typography>

  



          {/* <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }} mt={10}>
            <Button variant="text" onClick={() => navigate("/experience")} color="primary">Mike's Experience</Button>
            <Button variant="text" href={'https://www.linkedin.com/in/mikemarchitto/'} target="_blank" color="primary">Connect on LinkedIn</Button>
          </Stack> */}

        </Grid>



        {/* <Grid item xs={4}>
          <img src={monogram} width={'100%'} height={'100%'} alt="logo" />
        </Grid> */}


        <Grid container spacing={2} sx={{ paddingBottom: 10, alignItems: 'center'}}>

          <Grid item xs={12} sx={{marginTop: 20}}>
            <Box sx={{ height: 400, backgroundColor: 'blue' }}>
              <ActionAreaCardWide
                thumbnail={thumb_glorifi}
                name={'GloriFi'}
                descriptions={'Led UI design at a fintech startup for a banking app and website focused on financial wellness.'}
                route={'work/glorifi'} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginTop: 0}}>
            <Box sx={{ height: 400, backgroundColor: 'black' }}>
              <ActionAreaCard
                thumbnail={thumb_microsoft}
                name={'Microsoft HITS'}
                descriptions={'Led UI design for custom software UX research repository product at Microsoft.'}
              route={'work/microsoft-hits'} 
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} sx={{marginTop: 37}}>
            <Box sx={{ height: 700, backgroundColor: 'white' }}>
              <ActionAreaCard
                thumbnail={thumb_eddiebauer}
                name={'Eddie Bauer'}
                descriptions={'Led UI design at a boutique agency for Ecommerce website redesign.'}
                thumbHeight={"600"}
              route={'work/eddie-bauer'} 
              />
            </Box>
          </Grid>

       


          <Grid item xs={12} sx={{marginTop: 30}}>
            <Box sx={{ height: 400, backgroundColor: 'blue' }}>
              <ActionAreaCardWide
                thumbnail={thumb_silverback}
                name={'Silverback MMA'}
                descriptions={'Art direction and design for brand and website centered around mixed martial arts training.'}
              route={'work/silverback-mma'} 
              />
            </Box>
          </Grid>

        </Grid>

      </Grid>


      <Footer />
      <ScrollRestoration/>


    </Container>
  );
}

export default App;
