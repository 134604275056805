import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../assets/brand/monogram-white.svg'


function Footer() {
    return (
        <Box maxWidth="lg" sx={{ backgroundColor: '#3366CC', marginTop: 10, padding: 4, height: 400}} >



            <Grid container spacing={4} sx={{ position: "relative", zIndex: 5, paddingBottom: 10, }}>

                <Grid item xs={12} sm={4} md={1}>

                    <Box sx={{ width: '100%' }} mb={1}>

                        <Link to={`/`}>
                            {/* <img src={logo} className="App-logo" alt="logo" style={{ height: "8vmin" }} /> */}
                            <img src={logo} className="App-logo" alt="logo" style={{ height: "5vmin", }} />
                        </Link>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>

                    <Box sx={{ width: '100%' }} mb={2}>
                        <Typography variant="h1" color="white">Let’s build experiences together</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <Box sx={{ borderRadius: 2, height: '100%' }}>
                        <Stack spacing={1} >
                            <Typography variant="h6" color="white" mb={2} sx={{fontWeight: 'bold'}}>Contact</Typography>
                            <Typography variant="h6" color="white">mikemarchitto@gmail.com</Typography>
                            <Typography variant="h6" color="white">(206) 293-596zero</Typography>
                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                    <Box sx={{ borderRadius: 2, height: '100%' }}>
                        <Stack spacing={1} >
                            <Typography variant="h6" color="white" mb={2} sx={{fontWeight: 'bold'}}>Follow</Typography>
                            <a href={'https://www.behance.net/mikemarchitto'} target="_blank">
                                <Typography variant="h6" color="white">Behance</Typography>
                            </a>
                            <a href={'https://www.linkedin.com/in/mikemarchitto/'} target="_blank">
                                <Typography variant="h6" color="white">LinkedIn</Typography>
                            </a>

                        </Stack>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                    <Box sx={{ borderRadius: 2, height: '100%' }}>
                        <Stack spacing={1} >
                            <Typography variant="h6" color="white" mb={2} sx={{fontWeight: 'bold'}}>Remote Office</Typography>
                            <Typography variant="h6" color="white">Wicker Park</Typography>
                            <Typography variant="h6" color="white">Chicago, Illinois</Typography>

                        </Stack>
                    </Box>
                </Grid>

          
         

            </Grid>


        </Box>
    );
}

export default Footer;
