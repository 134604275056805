import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




import ActionAreaCard from '../../components/card'

import '../../styles/App.css';

import ResponsiveAppBar from '../../components/appbar';

import Footer from '../../components/footer'

import { ScrollRestoration } from "react-router-dom";


/// thumbnail images
import image_hero from '../../assets/project_three/hero.png'
import image_compaudit from '../../assets/project_three/eddiebauer_audit.png'
import image_designsystem from '../../assets/project_three/eddiebauer_designsystem.png'
import image_uiexplore from '../../assets/project_three/eddiebauer_uiexplore.png'
import image_wireframe from '../../assets/project_three/eddiebauer_wireframe.png'
import image_promo from '../../assets/project_three/eddiebauer_promo.png'
import image_icons from '../../assets/project_three/eddiebauer_icons.png'
import image_finaldesign from '../../assets/project_three/eddiebauer_pdp.png'


/// thumbnail images
import thumb_glorifi from '../../assets/thumbs/thumbnail_glorifi.png'
import thumb_eddiebauer from '../../assets/thumbs/thumbnail_eddiebauer.png'
import thumb_microsoft from '../../assets/thumbs/thumbnail_microsoft.png'
import thumb_silverback from '../../assets/thumbs/thumbnail_silverback.png'


function Work() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />


            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>
                <Grid item xs={0} sm={0} md={0} lg={12}>
                    <Typography variant="displayLarge" color="text.primary">
                    Eddie Bauer
                    </Typography>
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ marginBottom: 10 }}>



                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Industry</Typography>
                        <Typography variant="body1" color="text.primary">Ecommerce</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Project</Typography>
                        <Typography variant="body1" color="text.primary">Website Redesign</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>When</Typography>
                        <Typography variant="body1" color="text.primary">2018</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Role</Typography>
                        <Typography variant="body1" color="text.primary">UI/UX Designer</Typography>
                    </Stack>
                </Grid>

            </Grid>


            <Grid container spacing={2} sx={{ paddingBottom: 10, }}>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard
                            thumbnail={image_hero}
                        // name={'GloriFi'}
                        // descriptions={'GloriFi is a fintech startup offering banking and credit cards with a focus on financial wellness information via their mobile and web application.'}
                        // route={'/designSystem'} 
                        />
                    </Box>
                </Grid>


            </Grid>



            <Box sx={{ width: '100%', paddingTop: 4, }}>
                <Typography variant="h1" color="text.primary" mb={2}>Situation</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Eddie Bauer aims to revamp their Ecommerce platform with new features and brand aesthetics within six months, in order to enhance their marketing strategy and generate more leads.                    </Typography>
                </Grid>

            </Grid>

            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Task</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Build a stronger contemporary brand with current signature technologies, engage the outdoor apparel community, and create a product description page and branded design system that cater to the users' interests.                
                    </Typography>

                    </Grid>
            </Grid>


            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Result</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Following the launch, the findings of the qualitative research indicated that the level of satisfaction among users had increased, primarily because the design of the product was less obtrusive, and it allowed users to have greater control over the narrative of their reports.               
                    </Typography>

                     </Grid>

            </Grid>







            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Empathize</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Competitive Audit
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    In order to gain a deeper understanding for designs in the context of outdoor clothing websites, I conducted an audit and examined patterns and strategies to engage customers.                   
                     </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_compaudit} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>



            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Define</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Design System
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    In order to ensure that the user experience remained consistent, I made styles and UI components that could be handed off to the development team, resulting in a more polished and user-friendly end result.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_designsystem} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Ideate</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Wireframe Evaluation
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I collaborated closely with our UX designers to audit wireframes with the aim of gaining valuable insight into the user experience and to identify any areas that required improvement.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_wireframe} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_uiexplore} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        UI Exploration
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To create appealing mockups, I invented brand styles and design components to inform and bring the wireframes to life, reflecting the brand's values and aesthetic.
                    </Typography>
                </Grid>


            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Design</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Promotions Carousel
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Various reusable components were created for this carousel module so that customers can browse promotions.                
                        </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_promo} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    System Iconography
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To unify the brand's image, I created custom system icons that matched the brand's aesthetic.                       
                     </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_icons} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Product Details Page
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    The product page's modern and minimalistic design gives a sophisticated impression. The search function helps users navigate reviews and questions, boosting customer confidence in purchasing decisions.                        </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_finaldesign} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>



            <Grid container spacing={4} sx={{ paddingTop: 8, }}>

                <Grid item xs={12} lg={5} sx={{ marginTop: 5, paddingBottom: 0}}>
                    <Box sx={{ width: '100%', }}>
                        <Typography variant="displayLarge" color="text.primary" mb={2}>Next Project</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ marginTop: 5, paddingBottom: 20 }}>
                    <Box sx={{ height: 700, backgroundColor: 'white' }}>
                        <ActionAreaCard
                            thumbnail={thumb_microsoft}
                            name={'Microsoft HITS'}
                            descriptions={'Led UI design for custom software UX research repository product at Microsoft.'}
                            thumbHeight={"500"}
                        route={'../work/microsoft-hits'} 
                        />
                    </Box>
                </Grid>

            </Grid>




            <Footer />
            <ScrollRestoration/>


        </Container>
    );
}

export default Work;
