import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




import ActionAreaCard from '../../components/card'

import '../../styles/App.css';

import ResponsiveAppBar from '../../components/appbar';

import Footer from '../../components/footer'

import { ScrollRestoration } from "react-router-dom";


/// thumbnail images
import image_hero from '../../assets/project_two/hero.png'
import image_redlines from '../../assets/project_two/microsoft_redline.png'
import image_designsystem from '../../assets/project_two/microsoft_designsystem.png'
import image_audit from '../../assets/project_two/microsoft_audit.png'
import image_uiexplore from '../../assets/project_two/microsoft_uiexplore.png'
import image_wireframe from '../../assets/project_two/microsoft_wireframe.png'
import image_empathy from '../../assets/project_two/microsoft_empathymap.png'

import image_finaldesign from '../../assets/project_two/microsoft_cie.png'



/// thumbnail images
import thumb_eddiebauer from '../../assets/thumbs/thumbnail_eddiebauer.png'
import thumb_silverback from '../../assets/thumbs/thumbnail_silverback.png'



function Work() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />


            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>
                <Grid item xs={0} sm={0} md={0} lg={12}>
                    <Typography variant="displayLarge" color="text.primary">
                    Microsoft HITS
                    </Typography>
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ marginBottom: 10 }}>



                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Industry</Typography>
                        <Typography variant="body1" color="text.primary">Infotech</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Project</Typography>
                        <Typography variant="body1" color="text.primary">UX Research Repository</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>When</Typography>
                        <Typography variant="body1" color="text.primary">2019</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Role</Typography>
                        <Typography variant="body1" color="text.primary">UI/UX Designer</Typography>
                    </Stack>
                </Grid>

            </Grid>


            <Grid container spacing={2} sx={{ paddingBottom: 10, }}>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard
                            thumbnail={image_hero}
                        // name={'GloriFi'}
                        // descriptions={'GloriFi is a fintech startup offering banking and credit cards with a focus on financial wellness information via their mobile and web application.'}
                        // route={'/designSystem'} 
                        />
                    </Box>
                </Grid>


                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ height: 400, backgroundColor: 'black' }}>
                        <ActionAreaCard 
                            thumbnail={image_hero} 
                            name={'Silverback MMA'} 
                            descriptions={'Brandon Dudley is a professional mixed martial artist specializing in one on one training.'} 
                            route={'/designSystem'} />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ height: 400, backgroundColor: 'red' }}>
                    <ActionAreaCard 
                            thumbnail={image_hero} 
                            name={'Eddie Bauer'} 
                            descriptions={''} 
                            route={'/designSystem'} />
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard 
                            thumbnail={image_hero} 
                            name={'Microsoft HITS'} 
                            descriptions={''} 
                            route={'/designSystem'} />
                    </Box>
                </Grid> */}





            </Grid>



            <Box sx={{ width: '100%', paddingTop: 4, }}>
                <Typography variant="h1" color="text.primary" mb={2}>Situation</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
The objective of the Microsoft HITS team is to enhance the usability and functionality of the legacy system within a six-month timeline. The team plans to achieve this goal by implementing a new design system and style guide in the redesign and reskinning of the system.
                    </Typography>
                </Grid>

            </Grid>

            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Task</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Create an enhanced web tool software that utilizes Microsofts branding standards to help attract and engage more users within the organization.
                    </Typography>

 </Grid>

            </Grid>


            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Result</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Following the launch, the findings of the qualitative research indicated that the level of satisfaction among users had increased, primarily because the design of the product was less obtrusive, and it allowed users to have greater control over the narrative of their reports.                    </Typography>
   </Grid>

            </Grid>





            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Empathize</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Empathy Mapping
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To enhance our user experience, I engaged in empathy mapping workshops which gave me an immersive opportunity to gain a deeper understanding of our target audience.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_empathy} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_audit} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Legacy Build Audit                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To comply with the new design direction, I audited the HITS legacy version to identify areas where changes could be made.                    
                    </Typography>
                </Grid>


            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Define</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Design System
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I crafted a series of reusable UI components that could be seamlessly integrated throughout the product design cycle so we could maintain a consistent and streamlined user experience.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_designsystem} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Ideate</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Wireframe Evaluation
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Collaborating with stakeholders, I created low to medium-fidelity wireframes as a strategic tool to extract valuable insights and better understand the intricacies of the design goals.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_wireframe} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_uiexplore} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        UI Exploration
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    By considering every element of the wireframes and incorporating relevant design elements, I was able to ensure that the resulting mockups were both visually appealing and functional, bringing the wireframes to life and providing a clear representation of the final product.
                    </Typography>
                </Grid>


            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    User Interface Specs
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    In order to promote smooth and efficient teamwork during the implementation of the design system, the team decided to use Redline documentation as a way of communicating important information about the structure and functionality of all the components within the system.             
                     </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_redlines} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>



            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Design</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Content Ingestion Editor
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    This customizable tool facilitates the ingestion, editing, and collaborative management of UX research content for Microsoft,  providing valuable insights and data to the organization.
                                            </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_finaldesign} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>

                <Grid item xs={12} lg={5} sx={{ marginTop: 5, paddingBottom: 0 }}>
                    <Box sx={{ width: '100%', }}>
                        <Typography variant="displayLarge" color="text.primary" mb={2}>Next Project</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ marginTop: 5, paddingBottom: 20 }}>
                    <Box sx={{ height: 700, backgroundColor: 'white' }}>
                        <ActionAreaCard
                            thumbnail={thumb_silverback}
                            name={'Silverback MMA'}
                            descriptions={'Art direction and design for brand and website centered around mixed martial arts training.'}
                            thumbHeight={"500"}
                        route={'../work/silverback-mma'} 
                        />
                    </Box>
                </Grid>

            </Grid>




            <Footer />
            <ScrollRestoration/>


        </Container>
    );
}

export default Work;
