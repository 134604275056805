import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




import ActionAreaCard from '../../components/card'

import '../../styles/App.css';

import ResponsiveAppBar from '../../components/appbar';

import Footer from '../../components/footer'

import { ScrollRestoration } from "react-router-dom";


/// thumbnail images
import image_hero from '../../assets/project_one/hero.png'
import image_compaudit from '../../assets/project_one/glorifi_compaudit.png'
import image_designsystem from '../../assets/project_one/glorifi_designsystem.png'
import image_personas from '../../assets/project_one/glorifi_personas.png'
import image_uiexplore from '../../assets/project_one/glorifi_uiexplore.png'
import image_wireframe from '../../assets/project_one/glorifi_wireframe.png'
import image_features from '../../assets/project_one/glorifi_features.png'

import image_today from '../../assets/project_one/glorifi_today.png'
import image_snapshot from '../../assets/project_one/glorifi_snapshot.png'
import image_rewards from '../../assets/project_one/glorifi_rewards.png'
import image_accounts from '../../assets/project_one/glorifi_accounts.png'


/// thumbnail images
import thumb_glorifi from '../../assets/thumbs/thumbnail_glorifi.png'
import thumb_eddiebauer from '../../assets/thumbs/thumbnail_eddiebauer.png'



function Work() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />


            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>
                <Grid item xs={0} sm={0} md={0} lg={12}>
                    <Typography variant="displayLarge" color="text.primary">
                        GloriFi
                    </Typography>
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ marginBottom: 10 }}>



                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Industry</Typography>
                        <Typography variant="body1" color="text.primary">FinTech</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Project</Typography>
                        <Typography variant="body1" color="text.primary">Mobile Apps & Web App</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>When</Typography>
                        <Typography variant="body1" color="text.primary">2022</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Role</Typography>
                        <Typography variant="body1" color="text.primary">UI/UX Designer</Typography>
                    </Stack>
                </Grid>

            </Grid>


            <Grid container spacing={2} sx={{ paddingBottom: 10, }}>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard
                            thumbnail={image_hero}
                        // name={'GloriFi'}
                        // descriptions={'GloriFi is a fintech startup offering banking and credit cards with a focus on financial wellness information via their mobile and web application.'}
                        // route={'/designSystem'} 
                        />
                    </Box>
                </Grid>


                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ height: 400, backgroundColor: 'black' }}>
                        <ActionAreaCard 
                            thumbnail={image_hero} 
                            name={'Silverback MMA'} 
                            descriptions={'Brandon Dudley is a professional mixed martial artist specializing in one on one training.'} 
                            route={'/designSystem'} />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ height: 400, backgroundColor: 'red' }}>
                    <ActionAreaCard 
                            thumbnail={image_hero} 
                            name={'Eddie Bauer'} 
                            descriptions={''} 
                            route={'/designSystem'} />
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard 
                            thumbnail={image_hero} 
                            name={'Microsoft HITS'} 
                            descriptions={''} 
                            route={'/designSystem'} />
                    </Box>
                </Grid> */}





            </Grid>



            <Box sx={{ width: '100%', paddingTop: 4, }}>
                <Typography variant="h1" color="text.primary" mb={2}>Situation</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    GloriFi’s goal is to offer a functional financial banking app that focuses on financial wellness information via their mobile and web application. The timeline is to launch a beta and MVP application in eight months to validate market interest and secure more funding.                    </Typography>
                </Grid>

            </Grid>

            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Task</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Design a global financial news feed and financial data insights hub that engages the U.S. cultural and financial communities by building trust, security, and privacy when opening bank accounts.                    </Typography>
                </Grid>

            </Grid>


            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Result</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Following the initial release, which involved a successful launch of the app, it was reported that more than 40,000 individuals had downloaded the application, which was a significant achievement, as it ranked 11th among all financial apps available on the market.                    </Typography>
                </Grid>

            </Grid>


            {/* <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Action</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="subtitle1" color="text.primary" sx={{}}>Empathize</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Research and audit other banking and financial wellness applications.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Participate in empathy mapping workshops.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Synthesize personas from empathy mapping workshops.</Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="subtitle1" color="text.primary" sx={{}}>Define</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Work with Product Owners and other UX Designers to define features and specs. </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Establish a design system of reusable functional components and patterns.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Create a style guide to help ensure consistency across the product.</Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="subtitle1" color="text.primary" sx={{}}>Ideate</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Participate in design workshops.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Audit userflows and wireframes with UX Designers.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Contribute my personal perspective with the user in mind.</Typography>
                </Grid>




                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="subtitle1" color="text.primary" sx={{}}>Design</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Translate concepts and wireframes into effective user interface screens.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Design responsive translations for mobile, tablet and desktop.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Help validate developer build during the QA phase.</Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="subtitle1" color="text.primary" sx={{}}>Test</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Help create questions to create a dialogue with users.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Help create prototypes for qualitative testing on Usertesting.com.</Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="subtitle1" color="text.primary" sx={{}}>Design System</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Create design system, brand styles and reusable UI components in Figma.</Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ marginTop: 2 }}>Incorporate brand identity styles into screens and components.</Typography>
                </Grid>


            </Grid> */}




            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Empathize</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Competitive Audit
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I carefully reviewed several banking and financial apps, evaluating their features and identifying areas for improvement. This helped me gain a comprehensive understanding of the app landscape.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_compaudit} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_personas} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Empathy Map & Personas
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I joined empathy mapping workshops to comprehend our users better. The workshops aided me in creating personas that reflect their distinct qualities and motivations, revealing areas for improvement and leading to a better user experience.                    </Typography>
                </Grid>


            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Define</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Design System
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    This UI framework saved time and effort for the team by providing pre-built components and promoting best UI practices. The framework also allowed for easier maintenance and updates without compromising the site's integrity.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_designsystem} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Ideate</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Wireframe Evaluation
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I worked with UX designers to review user flows and wireframes, examining the design process and identifying areas for improvement. We discussed potential issues, and brainstormed solutions to ensure a seamless and user-friendly experience.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_wireframe} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_uiexplore} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        UI Exploration
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I used our brand styles and design system components to create consistent and visually appealing mockups. This approach ensured that our final product was effective and aligned with our brand's guidelines and design principles.                    </Typography>
                </Grid>


            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Design</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Feature: Today
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    This news feed presents global and financial news with a TikTok-style swipe interface. It's easy to browse and visually engaging for both casual readers and investors who want to stay informed.                
                    </Typography>
                    </Grid>

                <Grid item xs={12}>
                    <img src={image_today} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Feature: Snapshot
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    This dashboard helps you view income, expenses, investments, and debts. You can monitor progress, identify improvement areas, and make informed decisions.                        </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_snapshot} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Feature: Rewards
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    We redesigned and reskinned the vendor loyalty feature so users get rewards and discounts from their favorite service providers.                         </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_rewards} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Feature: Accounts
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Reusable marketing cards and user flow screens simplify banking account opening. This approach helps users select the right account and quickly complete the task.                        
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_accounts} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>

                <Grid item xs={12} lg={5} sx={{ marginTop: 5, paddingBottom: 0 }}>
                    <Box sx={{ width: '100%', }}>
                        <Typography variant="displayLarge" color="text.primary" mb={2}>Next Project</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={0} sx={{ marginTop: 5, paddingBottom: 20 }}>
                    <Box sx={{ height: 700, backgroundColor: 'white' }}>
                        <ActionAreaCard
                            thumbnail={thumb_eddiebauer}
                            name={'Eddie Bauer'}
                            descriptions={'Led UI design at a boutique agency for Ecommerce website redesign.'}
                            thumbHeight={"500"}
                        route={'../work/eddie-bauer'} 

                        />
                    </Box>
                </Grid>

            </Grid>





            <Footer />
            <ScrollRestoration/>


        </Container>
    );
}

export default Work;
