import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';


import logo from '../assets/brand/logo.svg'
import avatar from '../assets/brand/avatar.svg'


import '../styles/App.css';

import ResponsiveAppBar from '../components/appbar';

function Extra() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />

            <Grid container spacing={2} sx={{ paddingY: 10, }}>

                <Grid item xs={6}>
                    <h1>
                    Extra
                    </h1>
                </Grid>

                <Grid item xs={6}>
                    <p>
                        Since 2011, I have worked on many projects with many talented people. The experiences from each project have given me a broader perspective and a better understanding the role of a designer and creator of user experience. 

                </p>
                </Grid>

            </Grid>


            <Grid container spacing={2} sx={{ paddingBottom: 10,}}>

                <Grid item xs={12} >
                    <Box sx={{ height: 300,backgroundColor: 'grey'}}>
                            Design System Catalog
                    </Box>
                </Grid>

                <Grid item xs={6} >
                    <Box sx={{ height: 300,backgroundColor: 'black'}}>
                 
                    </Box>
                </Grid>

                <Grid item xs={6} >
                    <Box sx={{ height: 300,backgroundColor: 'black'}}>
                 
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <Box sx={{ height: 300,backgroundColor: 'grey'}}>
                 
                    </Box>
                </Grid>
        

            </Grid>


        </Container>
    );
}

export default Extra;
