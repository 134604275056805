import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import logo from '../assets/brand/logo.svg'
import avatar from '../assets/brand/avatar.svg'
import Footer from '../components/footer'

import '../styles/App.css';

import ResponsiveAppBar from '../components/appbar';
import { Button } from '@mui/material';

function Experience() {

    const [secondary, setSecondary] = useState(false);


    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />

            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>


                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h1" color="text.primary">
                        When working with product and development partners, I use design thinking to craft the best interfaces, no matter the size of the project.
                    </Typography>
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingBottom: 10, }}>

                <Grid item xs={4} >
                    <Stack spacing={2} paddingTop={2} sx={{}}>
                        <Typography variant="h1" color="text.primary">
                            Experience
                        </Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {['Fintech', 'Ecommerce', 'Infotech', 'Independent', 'Agency', 'Corporate']
                                .map((value) => (
                                    <ListItem
                                        key={value}
                                        disableGutters
                                    >
                                        <ListItemText
                                            primary={`${value}`}
                                            secondary={secondary ? 'Secondary text' : null}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    </Stack>
                </Grid>

                <Grid item xs={4} >
                    <Stack spacing={2} paddingTop={2} sx={{}}>
                        <Typography variant="h1" color="text.primary">
                            Skills
                        </Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {['UI/UX Design', 'Visual Design', 'Prototyping', 'Interaction Design', 'Responsive Design', 'Wireframing']
                                .map((value) => (
                                    <ListItem
                                        key={value}
                                        disableGutters
                                    >
                                        <ListItemText
                                            primary={`${value}`}
                                            secondary={secondary ? 'Secondary text' : null}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    </Stack>
                </Grid>

                <Grid item xs={4} >
                    <Stack spacing={2} paddingTop={2} sx={{}}>
                        <Typography variant="h1" color="text.primary">
                            Tools
                        </Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {['Figma', 'Sketch', 'Adobe', 'HTML/CSS', 'Material Design', 'MUI React Component Library']
                                .map((value) => (
                                    <ListItem
                                        key={value}
                                        disableGutters
                                    >
                                        <ListItemText
                                            primary={`${value}`}
                                            secondary={secondary ? 'Secondary text' : null}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    </Stack>
                </Grid>

            </Grid>

            <Box sx={{ textAlign: 'center' }}>
                <Button variant="outlined" size="large" href={'https://www.dropbox.com/s/lbcz1t069oa1r7p/mikemarchitto_resume.pdf?dl=0'} target="_blank" color="primary">Download Resume</Button>
            </Box>

            <Footer />

        </Container>
    );
}

export default Experience;
