
import * as React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import "@fontsource/roboto"


export const theme = createTheme({
  status: {
    danger: "orange",
  },
  
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#000000',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#0066ff',
      main: '#FFFFFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff'
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,

    text: {
      primary: "#000000",
      secondary: "#777777",
      accent: "#8BBBDA",
    }

  },



  typography: {
    fontFamily: 'Roboto, Arial',

    displayLarge: {
      fontSize: 96,
      fontWeight: 800,
    },
    h1: {
      fontSize: "2.813rem", //45px
      lineHeight: "3.25rem", //52px
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h2: {
      fontSize: "2.25rem", // 36px
      lineHeight: "2.75rem", //44px
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h3: {
      fontSize: "2.00rem",
      lineHeight: "2.5rem",
      fontWeight: 400,
      letterSpacing: 0,
    },
    h4: {
      fontSize: "1.75rem", //28px
      lineHeight: "2.25rem", //36px
      fontWeight: 400,
      letterSpacing: 0,
    },
    h5: {
      fontSize: "1.5rem", //24px
      lineHeight: "2.0rem", //32px
      fontWeight: 400,
      letterSpacing: 0,
    },
    h6: {
      fontSize: "1.25rem", //20px
      lineHeight: "1.75rem", //28px
      fontWeight: 500,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: "1.0rem", //16px
      lineHeight: "1.5rem", //24px
      fontWeight: 'bolder',
      letterSpacing: "0.009rem",
    },
    subtitle2: {
      fontSize: "0.875rem", //14px
      lineHeight: "1.25rem", //20px
      fontWeight: 500,
      letterSpacing: "0.006rem",
    },
    body1: {
      fontSize: "1.0rem", //16px
      lineHeight: "1.5rem", //24px
      fontWeight: 400,
      letterSpacing: "0.009rem",
    },
    body2: {
      fontSize: "0.875rem", //14px
      lineHeight: "1.25rem", //20px
      fontWeight: 400,
      letterSpacing: "0.006rem",
    },
    caption: {
      fontSize: "0.75rem", //12px
      lineHeight: "1.0rem", //16px
      fontWeight: 400,
      letterSpacing: "0.025rem",
    },

    button: {
      fontSize: "0.875rem", //14px
      lineHeight: "1.25rem", //20px
      fontWeight: 500,
      letterSpacing: "0.006rem",
      textTransform: "none",
      textDecoration: 'none'
    },
  },

});
