import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


import logo from '../assets/brand/logo.svg'
import avatar from '../assets/brand/avatar.svg'
import image_profile from '../assets/brand/contact_image.png'

import ActionAreaCard from '../components/card'

import '../styles/App.css';

import ResponsiveAppBar from '../components/appbar';

import Footer from '../components/footer'



function Contact() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />



            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'top' }} xs={12}>

                <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'top' }} xs={12} sm={12} md={12} lg={8}>

                    <Grid item xs={0} sm={0} md={0} lg={12}>

                        <Typography variant="h1" color="text.primary" mb={2} >Thank you for visiting. I’m available for work so let’s connect.</Typography>



                    </Grid>


                    <Grid item xs={12} sm={5} md={5} sx={{marginBottom: 4}}>
                        <Box sx={{ borderRadius: 2, height: '100%' }}>
                            <Stack spacing={1} >
                                <Typography variant="h6" color="text.primary" mb={2} sx={{ fontWeight: 'bold' }}>Contact</Typography>
                                <Typography variant="h6" color="text.primary">mikemarchitto@gmail.com</Typography>
                                <Typography variant="h6" color="text.primary">(206) 293-596zero</Typography>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3} sx={{marginBottom: 4}}>
                        <Box sx={{ borderRadius: 2, height: '100%' }}>
                            <Stack spacing={1} >
                                <Typography variant="h6" color="text.primary" mb={2} sx={{ fontWeight: 'bold' }}>Follow</Typography>
                                <a href={'https://www.behance.net/mikemarchitto'} target="_blank">
                                    <Typography variant="h6" color="text.primary">Behance</Typography>
                                </a>
                                <a href={'https://www.linkedin.com/in/mikemarchitto/'} target="_blank">
                                    <Typography variant="h6" color="text.primary">LinkedIn</Typography>
                                </a>

                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3} sx={{marginBottom: 4}}>
                        <Box sx={{ borderRadius: 2, height: '100%' }}>
                            <Stack spacing={1} >
                                <Typography variant="h6" color="text.primary" mb={2} sx={{ fontWeight: 'bold' }}>Remote Office</Typography>
                                <Typography variant="h6" color="text.primary">Wicker Park</Typography>
                                <Typography variant="h6" color="text.primary">Chicago, Illinois</Typography>

                            </Stack>
                        </Box>
                    </Grid>

                </Grid>

                <Grid container xs={12} sm={6} md={6} lg={4}>
                    <img src={image_profile} width={'100%'} height={'100%'} alt="logo" />
                </Grid>

            </Grid>






            {/* <Footer /> */}


        </Container>
    );
}

export default Contact;
