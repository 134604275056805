import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider} from '@mui/material/styles';


import App from './routes/App'
import Experience from './routes/Experience'
import Work from './routes/Work'
import Extra from './routes/Extra'
import Contact from './routes/Contact'
import ProjectOne from './routes/projectOne/main'
import ProjectTwo from './routes/projectTwo/main'
import ProjectThree from './routes/projectThree/main'
import ProjectFour from './routes/projectFour/main'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



import {theme} from './styles/Theme'


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "experience",
    element: <Experience />,
  },
  {
    path: "work",
    element: <Work />,
  },
  {
    path: "extra",
    element: <Extra />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "work/glorifi",
    element: <ProjectOne />,
  },
  {
    path: "work/microsoft-hits",
    element: <ProjectTwo />,
  },
  {
    path: "work/eddie-bauer",
    element: <ProjectThree />,
  },
  {
    path: "work/silverback-mma",
    element: <ProjectFour />,
  },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
