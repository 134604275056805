import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




import ActionAreaCard from '../../components/card'

import '../../styles/App.css';

import ResponsiveAppBar from '../../components/appbar';

import Footer from '../../components/footer'
import { ScrollRestoration } from "react-router-dom";



/// thumbnail images
import image_hero from '../../assets/project_four/hero.png'
import image_compaudit from '../../assets/project_four/silverback_audit.png'
import image_designsystem from '../../assets/project_four/silverback_designsystem.png'
import image_wireframe from '../../assets/project_four/silverback_wireframe.png'

import image_uiexplore from '../../assets/project_four/silverback_uiexplore.png'

import image_branding from '../../assets/project_four/silverback_branding.png'
import image_website from '../../assets/project_four/silverback_website.png'


/// thumbnail images
import thumb_glorifi from '../../assets/thumbs/thumbnail_glorifi.png'
import thumb_eddiebauer from '../../assets/thumbs/thumbnail_eddiebauer.png'
import thumb_microsoft from '../../assets/thumbs/thumbnail_microsoft.png'
import thumb_silverback from '../../assets/thumbs/thumbnail_silverback.png'


function Work() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />


            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>
                <Grid item xs={0} sm={0} md={0} lg={12}>
                    <Typography variant="displayLarge" color="text.primary">
                    Silverback MMA
                    </Typography>
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ marginBottom: 10 }}>



                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Industry</Typography>
                        <Typography variant="body1" color="text.primary">Mixed Martial Arts</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Project</Typography>
                        <Typography variant="body1" color="text.primary">Branding and Website</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>When</Typography>
                        <Typography variant="body1" color="text.primary">2018</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={4} md={3}>
                    <Stack>
                        <Typography variant="subtitle1" color="text.primary" mb={1}>Role</Typography>
                        <Typography variant="body1" color="text.primary">UI/UX Designer</Typography>
                    </Stack>
                </Grid>

            </Grid>


            <Grid container spacing={2} sx={{ paddingBottom: 10, }}>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard
                            thumbnail={image_hero}
                        // name={'GloriFi'}
                        // descriptions={'GloriFi is a fintech startup offering banking and credit cards with a focus on financial wellness information via their mobile and web application.'}
                        // route={'/designSystem'} 
                        />
                    </Box>
                </Grid>


            </Grid>



            <Box sx={{ width: '100%', paddingTop: 4, }}>
                <Typography variant="h1" color="text.primary" mb={2}>Situation</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
Silverback MMA aims to launch a functional brochure website within six months that introduces users to Brandon Dudley MMA trainer and attracts new customers to his training program through marketing.
                    </Typography>
                </Grid>

            </Grid>

            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Task</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Create a brochure website featuring a high-quality mixed martial arts training program with a training video feature, in order to engage and attract interest from the MMA community.                    </Typography>

                    </Grid>
            </Grid>


            <Box sx={{ width: '100%', paddingTop: 4, marginTop: 10 }}>
                <Typography variant="h1" color="text.primary" mb={2}>Result</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 1, }}>

                <Grid item xs={12} sm={8} md={8} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    Within a month after the launch, Brandon's client list experienced a remarkable surge, increasing by an impressive 120% from 8 to 20 people.                    </Typography>

                     </Grid>

            </Grid>







            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Empathize</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Competitive Audit
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I used the UFC gym's aesthetic and functional aspects as my main inspiration, but also researched other fitness and martial arts facilities for a well-rounded approach, incorporating the best features into my design.                     </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_compaudit} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>



            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Define</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        Styles Guide
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To ensure consistent brand identity, I designed a style guide covering typography, colors, logos, imagery, graphics, language, and tone.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_designsystem} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Ideate</Typography>
            </Box>

            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Wireframing
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To enhance the overall user experience and ensure that the design met the specific requirements of the client, I utilized a combination of simple sketches and medium fidelity wireframes to test the interface design.                    </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_wireframe} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={8} md={8}>
                    <img src={image_uiexplore} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>

                <Grid item xs={12} sm={4} md={4} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                        UI Exploration
                    </Typography>
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    To produce mockups that are both thorough and visually attractive, I made sure that the design components aligned with the overall brand style, resulting in a seamless and refined end result.                    </Typography>
                </Grid>


            </Grid>


            <Box sx={{ width: '100%', marginTop: 20 }}>
                <Typography variant="displayLarge" color="text.primary" mb={2}>Design</Typography>
            </Box>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Branding
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    I designed the silverback gorilla mark using a combination of illustration and hand-lettering techniques. The design captures the brand's personality and values, making it easily recognizable and memorable to consumers.                        </Typography>
                </Grid>

                <Grid item xs={12}>
                    <img src={image_branding} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


            <Grid container spacing={4} sx={{ paddingTop: 8, }}>
                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h1" color="text.primary" sx={{ marginBottom: 2 }}>
                    Website
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6} >
                    <Typography variant="h6" color="text.primary" sx={{}}>
                    The website provides users with a comprehensive introduction to Brandon, an MMA expert. Users can learn about his background, experience, and philosophy, and access training videos to develop their skills and achieve their goals in MMA.                
                    </Typography>
                    </Grid>

                <Grid item xs={12}>
                    <img src={image_website} width={'100%'} height={'100%'} alt='Large Pizza' />
                </Grid>
            </Grid>


  



            <Grid container spacing={4} sx={{ paddingTop: 8, }}>

                <Grid item xs={12} lg={5} sx={{ marginTop: 5, paddingBottom: 0 }}>
                    <Box sx={{ width: '100%', }}>
                        <Typography variant="displayLarge" color="text.primary" mb={2}>Next Project</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ marginTop: 5, paddingBottom: 20 }}>
                    <Box sx={{ height: 700, backgroundColor: 'white' }}>
                        <ActionAreaCard
                            thumbnail={thumb_glorifi}
                            name={'GloriFi'}
                            descriptions={'Led UI design at a fintech startup for a banking app and website focused on financial wellness.'}
                            thumbHeight={"500"}
                        route={'../work/glorifi'} 
                        />
                    </Box>
                </Grid>

            </Grid>




            <Footer />
            <ScrollRestoration/>


        </Container>
    );
}

export default Work;
