import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


import logo from '../assets/brand/logo.svg'
import avatar from '../assets/brand/avatar.svg'

import ActionAreaCard from '../components/card'

import '../styles/App.css';

import ResponsiveAppBar from '../components/appbar';

import Footer from '../components/footer'

/// thumbnail images
import thumb_glorifi from '../assets/thumbs/thumbnail_glorifi.png'
import thumb_eddiebauer from '../assets/thumbs/thumbnail_eddiebauer.png'
import thumb_microsoft from '../assets/thumbs/thumbnail_microsoft.png'
import thumb_silverback from '../assets/thumbs/thumbnail_silverback.png'


function Work() {
    return (
        <Container maxWidth="lg">

            <ResponsiveAppBar />


            <Grid container spacing={2} sx={{ paddingY: 10, alignItems: 'center' }}>
                <Grid item xs={0} sm={0} md={0} lg={6}>
                    <Typography variant="displayLarge" color="text.primary">
                        Work
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Typography variant="subtitle1" color="text.primary">
                    I have 10+ years experience working with companies such as Microsoft, Starbucks, Eddie Bauer, Morningstar, SIFF as well as many startups and small businesses.                        </Typography>
                </Grid>
            </Grid>


            <Grid container spacing={2} sx={{ paddingBottom: 10, }}>

                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard 
                            thumbnail={thumb_glorifi} 
                            name={'GloriFi'} 
                            descriptions={'Lead UI design at Fintech startup for banking app and website focused on financial wellness.'} 
                            route={'glorifi'} />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ height: 400, backgroundColor: 'red' }}>
                    <ActionAreaCard 
                            thumbnail={thumb_eddiebauer} 
                            name={'Eddie Bauer'} 
                            descriptions={'Lead UI design at boutique agency for Ecommerce website redesign.'} 
                            route={'eddie-bauer'} 
                            />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ height: 400, backgroundColor: 'black' }}>
                        <ActionAreaCard 
                            thumbnail={thumb_microsoft} 
                            name={'Microsoft'} 
                            descriptions={'Lead UI design at corporate startup for Infotech product focused on UX research re-positing.'} 
                            route={'microsoft-hits'} 
                            />
                    </Box>
                </Grid>

                
                <Grid item xs={12} >
                    <Box sx={{ height: 400, backgroundColor: 'blue' }}>
                        <ActionAreaCard 
                            thumbnail={thumb_silverback} 
                            name={'Silverback MMA'} 
                            descriptions={'Lead UI design for startup website centered on mixed martial arts training.'} 
                            route={'silverback-mma'} 
                            />
                    </Box>
                </Grid>

            </Grid>

            <Footer />


        </Container>
    );
}

export default Work;
