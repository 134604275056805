import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import { useNavigate } from "react-router-dom";


export default function ActionAreaCard({ thumbnail, name, descriptions, route, thumbHeight }) {

    const navigate = useNavigate();


    const onHandleNavigate = (route) => {
        navigate(route)
        // navigate('/ListMap', { state: { pageTitle: title, queryIndex: index } })
    }

    return (
        <Card elevation={false} sx={{ height: 'auto', borderRadius: 0 }} >
            <CardActionArea
                sx={{ position: 'relative' }}
                onClick={() => { onHandleNavigate(route) }}
            >
                <CardMedia
                    component="img"
                    height={thumbHeight || "400"}
                    image={thumbnail}
                    alt="green iguana"
                />

                <CardContent
                    sx={{ padding: 0, zIndex: 2, marginTop: 2 }}
                >
                    <Typography gutterBottom variant="h1" color="black">
                        {name}
                    </Typography>
                    <Typography variant="h6" color="black" sx={{  }}>
                        {descriptions}
                    </Typography>
                </CardContent>

            </CardActionArea>
        </Card>
    );
}