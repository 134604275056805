import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
import logo from '../assets/brand/logo.svg'
import logo_mike from '../assets/brand/logo_mike.svg'

import {
    Link,
} from "react-router-dom";


import { useNavigate } from "react-router-dom";


const pages = [{ route: '/experience', label: 'Experience' }, { route: '/work', label: 'Work' }, { route: '/contact', label: 'Contact' }];

function ResponsiveAppBar({ showLogo }) {

    const navigate = useNavigate();


    return (
        <AppBar elevation={0} maxWidth="xl" position="static" sx={{ backgroundColor: 'white', paddingY: 2, }}>
            <Toolbar disableGutters sx={{ justifyContent: 'space-between', backgroundColor: 'white', }}>

                {showLogo == false ?
                    <Box sx={{flexGrow: 1}}></Box>
                    :
                    <Link to={`/`}>
                        {/* <img src={logo} className="App-logo" alt="logo" style={{ height: "8vmin" }} /> */}
                        <img src={logo_mike} className="App-logo" alt="logo" style={{ height: "5vmin" }} />

                    </Link>
                }


                <Box sx={{  display: { xs: 'flex', md: 'flex', }, alignSelf: 'flex-end'}}>
                <Button variant="text" sx={{ my: 2, color: 'black', display: 'block' }} onClick={() => navigate("/experience")} color="primary">Experience</Button>
                <Button variant="text" sx={{ my: 2, color: 'black', display: 'block' }} onClick={() => navigate("/")} color="primary">Work</Button>
                <Button variant="text" sx={{ my: 2, color: 'black', display: 'block' }} onClick={() => navigate("/contact")} color="primary">Contact</Button>


                    {/* {pages.map((page) => (
                        <Link to={page.route}>
                            <Button
                                key={page}
                                onClick={{}}
                                sx={{ my: 2, color: 'black', display: 'block' }}
                            >
                                {page.label}
                            </Button>
                        </Link>
                    ))} */}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default ResponsiveAppBar;